import Vue from 'vue'
import Vuex from 'vuex'

import VuexORM from "@vuex-orm/core";
import database from "@/database";


// import createLogger from '../../../src/plugins/logger'

Vue.use(Vuex);


const debug = process.env.NODE_ENV !== 'production'

// Create Vuex Store and register database through Vuex ORM.
const store = new Vuex.Store({
  plugins: [VuexORM.install(database)],
});

export default store;