import { Model } from "@vuex-orm/core";

export default class VicHourGroup extends Model {
  static entity = "vicHourGroups";
  static url = "/vichourgroups";
  static friendlyName = "Vic Hour Group";

  static state() {
    return {};
  }

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(null),
    };
  }
}
