import { Database } from '@vuex-orm/core'
import User from '../models/User'
import TimeEntry from '../models/TimeEntry'
import BookableResource from '../models/BookableResource'
import ProjectTask from '../models/ProjectTask'
import VicHourCode from '../models/VicHourCode'
import VicHourGroup from '../models/VicHourGroup'
import MsdynUser from '../models/MsdynUser'
import MsdynProject from '../models/MsdynProject'
import General from '../store/modules/general'


const database = new Database()

database.register(User, General)
database.register(TimeEntry)
database.register(BookableResource)
database.register(ProjectTask)
database.register(VicHourCode)
database.register(VicHourGroup)
database.register(MsdynUser)
database.register(MsdynProject)









// console.log(database.schemas)


export default database