import { Model } from "@vuex-orm/core";

export default class MsdynUser extends Model {
  static entity = "MsdynUsers";
  static url = "/MsdynUser";
  static friendlyName = "MsdynUser";
  // static primaryKey = "MsdynUsername";

  static state() {
    return {
    };
  }

  static fields() {
    return {
      id: this.attr(null),
    };
  }

}
