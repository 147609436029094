import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
  //   meta: {
  //     drawerMenuCategory: 'login'
  //   }
  // },
  {
    path: '/',
    name: 'People',
    component: () => import(/* webpackChunkName: "about" */ '../views/Users.vue'),
    meta: {
      drawerMenuCategory: 'home'
    }
  },
  {
    path: '/timesheet',
    name: 'Timesheet',
    component: () => import(/* webpackChunkName: "about" */ '../views/Timesheet.vue'),
    meta: {
      drawerMenuCategory: 'home'
    }
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () => import(/* webpackChunkName: "about" */ '../views/Projects.vue'),
    meta: {
      drawerMenuCategory: 'home'
    }
  },   
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
