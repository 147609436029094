import { Model } from "@vuex-orm/core";
import VicHourCode from "./VicHourCode";
import VicHourGroup from "./VicHourGroup";

export default class ProjectTask extends Model {
  static entity = "projectTasks";
  static url = "/projecttasks";
  static friendlyName = "Project task";

  static state() {
    return {};
  }

  static fields() {
    return {
      id: this.attr(null),
      msdynSubject: this.attr(null),
      vicHourCodeId: this.attr(null),
      vicHourGroupId: this.attr(null),
      vicHourCode: this.belongsTo(VicHourCode, "vicHourCodeId"),
      vicHourGroup: this.belongsTo(VicHourGroup, "vicHourGroupId"),
    };
  }
}
