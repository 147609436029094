import Vue from "vue";
// import * as VeeValidate from 'vee-validate';
import App from "./App.vue";

import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

// import msal from "vue-msal";

// Vue.use(VeeValidate);
Vue.config.productionTip = false;
// Vue.prototype.$msalInstance = {};


new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
