import { Model } from "@vuex-orm/core";

export default class VicHourCode extends Model {
  static entity = "vicHourCodes";
  static url = "/vichourcodes";
  static friendlyName = "Vic Hour Code";

  static state() {
    return {};
  }

  static fields() {
    return {
      id: this.attr(null),
      code: this.attr(null),
    };
  }
}
