import { Model } from "@vuex-orm/core";

export default class MsdynProject extends Model {
  static entity = "MsdynProjects";
  static url = "/MsdynProject";
  static friendlyName = "MsdynProject";
  // static primaryKey = "MsdynUsername";

  static state() {
    return {
    };
  }

  static fields() {
    return {
      id: this.attr(null),
      msdynSubject: this.attr(null),
      vicProjectNumber: this.attr(null),
      internal: this.boolean(false),
    };
  }

}
