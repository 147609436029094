import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css' 

Vue.use(Vuetify);

const vuetifyOpts = {
  rtl: false,
  breakpoint: {
    thresholds: {
      xs: 600,
      sm: 960,
      md: 1280,
      lg: 2160,
    },
    scrollBarWidth: 16,
  },  
  theme: {
    dark: false,
    themes: {
      dark: {
        primary: '#21CFF3',
        accent: '#57A098',
        secondary: '#5D7884',
        success: '#57A098',
        info: '#2196F3',
        warning: '#9A4704',
        error: '#FF5252',
        lightbackground: '#EDEDE1',
        darkbackground: '#A9AB9E',
        vicblue: '#009bff',
        vicyellow: '#D2B468',
        outlookblue: '#0072C6',
        teamspurple: '#464EB8'
      },
      light: {
        primary: '#16577C',
        accent: '#57A098',
        secondary: '#5D7884',
        success: '#57A098',
        info: '#2196F3',
        warning: '#9A4704',
        error: '#FF5252',
        lightbackground: '#EDEDE1',
        darkbackground: '#A9AB9E',
        vicblue: '#009bff',
        vicyellow: '#D2B468',
        outlookblue: '#0072C6',
        teamspurple: '#464EB8'
      }
    }
  }
}



export default new Vuetify(vuetifyOpts);
