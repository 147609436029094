import { Model } from "@vuex-orm/core";
import MsdynUser from "./MsdynUser";

export default class TimeEntry extends Model {
  static entity = "bookableResources";
  static url = "/bookableresources";
  static friendlyName = "Bookable resource";

  static state() {
    return {};
  }

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(null),
      msdynUserId: this.attr(null),
      msdynUser: this.belongsTo(MsdynUser, "msdynUserId"),
      contractHours: this.attr(null),
    };
  }
}
