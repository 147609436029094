// initial state
const state = () => ({
  drawerMenuState: "home",
  tabMenuState: null,
  currentView: "home",
  appVersion: process.env.PACKAGE_VERSION || "0",
  buildNumber: process.env.VERSION || "0",

});

// getters
const getters = {
  drawerMenuState: (state) => state.drawerMenuState,
  tabMenuState: (state) => state.tabMenuState,
  appVersion: (state) => state.appVersion || "0",
  buildNumber: (state) => state.buildNumber || "0",
};

// actions
const actions = {};

// mutations
const mutations = {
  setDrawerMenuState(state, drawerMenuState) {
    state.drawerMenuState = drawerMenuState;
    switch (drawerMenuState) {
      case "home":
        state.tabMenuState = "General";
        break;
    }
  },
  setTabMenuState(state, tabMenuState) {
    state.tabMenuState = tabMenuState;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
